import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117')
];

export const server_loads = [];

export const dictionary = {
		"/(app)": [23,[2]],
		"/(app)/accounting": [24,[2,3]],
		"/(app)/accounting/autopays": [25,[2,3]],
		"/(app)/accounting/baiimports": [26,[2,3]],
		"/(app)/accounting/banks": [27,[2,3]],
		"/(app)/accounting/banks/[id=integer]": [28,[2,3]],
		"/(app)/accounting/eboximports": [29,[2,3]],
		"/(app)/accounting/lockboximports": [30,[2,3]],
		"/(app)/accounting/lockboximports/[id=integer]": [31,[2,3]],
		"/(customer)/account": [106,[18,19]],
		"/(customer)/account/preferences": [107,[18,19]],
		"/(customer)/autopay": [108,[18]],
		"/(app)/clients": [32,[2]],
		"/(app)/clients/new": [52,[2]],
		"/(app)/clients/[id=integer]": [33,[2,4]],
		"/(app)/clients/[id=integer]/datasources": [34,[2,4]],
		"/(app)/clients/[id=integer]/datasources/edit/[datasourceid=integer]": [35,[2,4]],
		"/(app)/clients/[id=integer]/datasources/new": [36,[2,4]],
		"/(app)/clients/[id=integer]/fields": [37,[2,4]],
		"/(app)/clients/[id=integer]/lenderfields": [38,[2,4]],
		"/(app)/clients/[id=integer]/lenders": [39,[2,4]],
		"/(app)/clients/[id=integer]/lenders/new": [42,[2,4]],
		"/(app)/clients/[id=integer]/lenders/[lenderId=integer]": [40,[2,4,5]],
		"/(app)/clients/[id=integer]/lenders/[lenderId=integer]/balance": [41,[2,4,5]],
		"/(app)/clients/[id=integer]/logs": [43,[2,4]],
		"/(app)/clients/[id=integer]/products": [44,[2,4]],
		"/(app)/clients/[id=integer]/products/new": [45,[2,4]],
		"/(app)/clients/[id=integer]/status": [46,[2,4]],
		"/(app)/clients/[id=integer]/status/edit/[[statusCode]]": [47,[2,4,6]],
		"/(app)/clients/[id=integer]/status/new": [48,[2,4]],
		"/(app)/clients/[id=integer]/users": [49,[2,4,7]],
		"/(app)/clients/[id=integer]/users/edit/[[userid=integer]]": [51,[2,4,7]],
		"/(app)/clients/[id=integer]/users/[userid=integer]": [50,[2,4,7]],
		"/(auth)/contact": [100,[17]],
		"/(auth)/forgotpassword": [101,[17]],
		"/(auth)/login": [102,[17]],
		"/(auth)/logout": [103,[17]],
		"/notices": [117,[22]],
		"/(app)/obligations": [53,[2]],
		"/(app)/obligations/[id=integer]": [54,[2,8]],
		"/(app)/obligations/[id=integer]/activities": [55,[2,8]],
		"/(app)/obligations/[id=integer]/communication": [56,[2,8]],
		"/(app)/obligations/[id=integer]/contacts": [57,[2,8]],
		"/(app)/obligations/[id=integer]/contacts/add": [58,[2,8]],
		"/(app)/obligations/[id=integer]/customerPortal": [59,[2,8]],
		"/(app)/obligations/[id=integer]/data": [60,[2,8]],
		"/(app)/obligations/[id=integer]/details": [61,[2,8]],
		"/(app)/obligations/[id=integer]/ledgers/[ledger]": [62,[2,8]],
		"/(app)/obligations/[id=integer]/payments": [63,[2,8]],
		"/(app)/obligations/[id=integer]/payments/adjustment": [64,[2,8]],
		"/(app)/obligations/[id=integer]/payments/autopays": [65,[2,8]],
		"/(app)/obligations/[id=integer]/payments/autopays/new": [67,[2,8,10]],
		"/(app)/obligations/[id=integer]/payments/autopays/[autopayId=integer]": [66,[2,8,9]],
		"/(app)/obligations/[id=integer]/payments/methods": [68,[2,8]],
		"/(app)/obligations/[id=integer]/payments/payment": [69,[2,8,11]],
		"/(app)/obligations/[id=integer]/statements": [70,[2,8]],
		"/(customer)/payments": [110,[18,20]],
		"/(customer)/payments/methods": [111,[18,20]],
		"/(customer)/payments/methods/bank-account": [112,[18,20]],
		"/(customer)/payments/methods/credit-card": [113,[18,20]],
		"/(customer)/payment": [109,[18]],
		"/(open)/privacypolicy": [115,[21]],
		"/(app)/products/[id=integer]": [71,[2,12]],
		"/(app)/products/[id=integer]/dataexports": [72,[2,12]],
		"/(app)/products/[id=integer]/dataexports/[[exportid=integer]]/edit": [73,[2,12]],
		"/(app)/products/[id=integer]/dataexports/[[exportid=integer]]/history": [74,[2,12]],
		"/(app)/products/[id=integer]/dataimports": [75,[2,12,13]],
		"/(app)/products/[id=integer]/dataimports/[[importId=integer]]/edit": [76,[2,12,13]],
		"/(app)/products/[id=integer]/dataimports/[importId=integer]/history": [77,[2,12,13,14]],
		"/(app)/products/[id=integer]/dataimports/[importId=integer]/history/[historyId=integer]/records": [78,[2,12,13,14]],
		"/(app)/products/[id=integer]/documents": [79,[2,12]],
		"/(app)/products/[id=integer]/documents/edit/[[documenttemplateid=integer]]": [80,[2,12]],
		"/(app)/products/[id=integer]/fees": [81,[2,12]],
		"/(app)/products/[id=integer]/fields": [82,[2,12]],
		"/(app)/products/[id=integer]/ledgers": [83,[2,12]],
		"/(app)/products/[id=integer]/ledgers/edit/[[ledgerCode]]": [84,[2,12]],
		"/(app)/products/[id=integer]/obligationfields": [85,[2,12]],
		"/(app)/products/[id=integer]/returnreasons": [86,[2,12]],
		"/(app)/products/[id=integer]/scripts": [87,[2,12]],
		"/(app)/products/[id=integer]/scripts/[[scriptid=integer]]/edit": [88,[2,12]],
		"/(app)/products/[id=integer]/scripts/[scriptid=integer]": [89,[2,12,15]],
		"/(app)/products/[id=integer]/segments": [90,[2,12]],
		"/(app)/products/[id=integer]/update": [91,[2,12]],
		"/(app)/refresh": [92,[2]],
		"/(auth)/register": [104,[17]],
		"/(app)/reports": [93,[2]],
		"/(auth)/resetpassword": [105,[17]],
		"/(app)/statements": [94,[2]],
		"/(app)/statements/[statementid=integer]": [95,[2,16]],
		"/(app)/tasks": [96,[2]],
		"/(app)/tasks/[id=integer]": [97,[2]],
		"/(open)/terms": [116,[21]],
		"/(app)/user/activities": [98,[2]],
		"/(app)/user/edit": [99,[2]],
		"/(customer)/view-statements": [114,[18]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';